import React, { memo } from "react";
import { Modal } from "react-bootstrap";

const OrderModal = ({ 
  children, 
  show=false,
  setShow=()=>{},
  title=""
}) => {
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {title && <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>}

        <Modal.Body>
          {children}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default memo(OrderModal);
