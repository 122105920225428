import React, { useState, useEffect, memo } from 'react';
import utils from '../utils';

import DetailsItem from "./ProductCard";

const OrderDetails = ({
    title="",
    mode="single",
    info={},
    formData={},
    showAll=()=>{}
}) => {
    const [details, setdetails] = useState({});

    useEffect(() => {
        if (utils.present(info)) {
            // utils.log('info..:', info?.products);

            if (utils.present(info?.products)) {
                setdetails({
                    ...info?.products[0],
                    shipping: utils.formatAmount(info?.delivery_cost),
                    price: utils.formatAmount(info?.item_cost),
                    quantity: utils.formatAmount(info?.products[0].quantity || 1, 0),
                    discount: utils.formatAmount(info?.items_discount),
                    sub_total: utils.formatAmount(info?.item_cost),
                    total: utils.formatAmount(parseFloat(info?.item_cost) + parseFloat(info?.delivery_cost)),
                    currency: info?.currency?.symbol,
                    url: info?.products[0]?.images[0]?.url
                });
            }
        }
    }, [info]);
    
    return (
        <div className="bg-white rounded-lg px-3 pt-3">
            {title && 
                <h4 className="text-center">{title}</h4>
            }

            {mode === "all" ?
                info?.products?.map((el, index) => (
                    <div className="pb-4" key={index}>
                        <DetailsItem
                            mode={mode}
                            formData={formData}
                            item={{
                                ...el,
                                price: utils.formatAmount(el.pivot.price),
                                quantity: utils.formatAmount(el.pivot.quantity, 0),
                                currency: info?.currency?.symbol,
                                url: el?.images[0]?.url
                            }}
                        />
                    </div>
                ))
            :
                <DetailsItem mode={mode} item={details} formData={formData} />
            }

            {mode === "single" &&
                <div className="w-100 text-center text-primary py-2 font-weight-bold cursor-pointer"
                    onClick={() => showAll()}
                >
                    View all orders
                </div>
            }
        </div>
    )
};

export default memo(OrderDetails);