
export const useQueryParams = () => {
  const params = new URLSearchParams(window.location.search);

  return new Proxy(params, {
    get(target, prop) {
      return target.get(prop);
    },
  });
};

export const formatAmount = (number, dp=2) => {
  if (number) {
    const sanitized = parseFloat(number.toString().split(",").join(""));
    const fomattedNum = dp ? parseFloat(sanitized).toFixed(dp) : parseInt(sanitized)
    return fomattedNum;
  } else return number;
};

export const log = (...args) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(...args);
  }
};

export const present = (value) => {
  if (!value) {
    return false
  }
  if (typeof value === 'object') {
    if (Object.keys(value).length > 0) {
      return true
    }
    return false
  }
  if (Array.isArray(value) || typeof value === 'string') {
    return value && value.length > 0
  } else {
    return !!value
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  useQueryParams, formatAmount, log, present
};
