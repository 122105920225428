import React, { useState, useCallback, memo } from "react";
import { 
  CardElement, 
  PaymentElement, 
  useStripe, 
  useElements 
} from "@stripe/react-stripe-js";
import Swal from 'sweetalert2';
import "../assets/styles/global.css";
import utils from "../utils";
import loading from "../assets/images/oval.svg";

const options = {
  appearance: {
    theme: 'stripe',
    variables: {
      fontWeightNormal: '500',
      borderRadius: '5px',
      colorBackground: 'white',
      colorPrimary: '#6a1b9a',
      colorPrimaryText: 'white',
      spacingGridRow: '15px'
    },
    rules: {
      '.Label': {
        marginBottom: '6px'
      },
      '.Tab, .Input, .Block': {
        boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)',
        padding: '12px'
      }
    }
  }   
};

const cardOptions = {
  style: {
    base: {
      color: "#6a1b9a",
      borderRadius: "6px",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#6a1b9a"
      }
    },
    invalid: {
      paddingTop: '5px',
      textAlign: 'center',
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  },
};

const CheckoutForm = ({
  elementType,
  clientSecret,
  amount="",
  formData={},
  GetClientSecret=()=>{},
  setOsiUrl=()=>{},
  handlePaymentSuccess=()=>{},
  handlePaymentFailed=()=>{}
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [isReady, setisReady] = useState(false);

  const getStatus = (payment_status) => {
    if (payment_status) {
      let status = payment_status.toLowerCase();
      switch (status) {
        case 'cancelled':
          return 'CANCELLED';
        case 'payment_failed':
          return 'FAILED';
        case 'processing':
          return 'PENDING';
        case 'requires_action':
          return 'QUEUED';
        case 'succeeded':
          return 'SUCCESS';
      
        default:
          return payment_status;
      }
    } else return '';
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    if (clientSecret) {
      let updatedData = Object.assign({}, formData);
      updatedData.client_secret = clientSecret

      confirmPayment(updatedData);
    } else {
      GetClientSecret('stripe')
      .then((response) => {
        confirmPayment(response);
      })
      .catch(() => {
        confirmPayment();
      })      
    }
    
    async function confirmPayment (info) {
      if (amount === '0.00') {
        setProcessing(false);
        Swal.fire({
          title: 'Invalid Amount',
          text: `Total amount cannot be less than ${formData?.currency} 1.00`,
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonText: 'OK',
          // footer: `Need assistance? <a href=${process.env.REACT_APP_support} target="_blank" rel="noreferrer"><span class="black-text pl-1">Chat with us</span></a>`
        })
        .then(() => {
        })
        return;
      }    

      if (!info?.client_secret) {
          setProcessing(false);
          Swal.fire({
              title: 'Payment Error',
              text: 'A payment error occured. Please try again',
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonText: 'OK',
              footer: `Need assistance? <a href=${process.env.REACT_APP_support} target="_blank" rel="noreferrer"><span class="black-text pl-1">Chat with us</span></a>`,
          })
          .then(() => {
              // history.goBack();
          })
              
          return;
      };

      let payload; 

      if (clientSecret) {
        payload = await stripe.confirmPayment({
          elements, 
          confirmParams: { 
            return_url: info?.return_url 
          },
          redirect: "if_required"
        });
      } else {
        payload = await stripe.confirmCardPayment(
          info.client_secret, 
          {
            payment_method: {
              card: elements.getElement(CardElement)
            }
          }
        );
      };
  
      // handle payment processing response
      if (payload.error) {
        setProcessing(false);
        handlePaymentFailed(payload.error.message);
      } else {
        setOsiUrl(`https://plendify.ositracker.com/sales/salejs/amount:${payload?.paymentIntent?.amount/100}/transaction:${info?.request_id}`);
        setError(null);
        setProcessing(false);
        setSucceeded(true);
  
        handlePaymentSuccess(info?.request_id, getStatus(payload?.paymentIntent?.status))    
      }
    }
  };

  const handleOnReady = useCallback(() => {
    setisReady(true);
  }, []);
  
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {elementType === "payment" ?
        <>
          <PaymentElement
            id="payment-element"
            options={options}
            onChange={handleChange}
            onReady={() => handleOnReady()}
          />

          {!isReady &&
            <div className="fade w-100 d-flex justify-content-center">
              <img src={loading} width="25" alt="" />
            </div>
          } 
        </>
      :
        <CardElement 
          id="card-element"
          className="rounded-lg"
          options={cardOptions}
          onChange={handleChange}          
        />
      }

      <button
        disabled={processing || disabled || succeeded}
        id="submit"
        className="mt-3 rounded-lg"
      >
        <span id="button-text">
          {processing ?
            <div className="spinner" id="spinner"></div>
          :
            `PAY ${formData?.currency} ${utils.formatAmount(amount)}`
          }
        </span>
      </button>
      
      <div className="pt-1 invisible">
        {error && (<div className="card-error" role="alert">{error}</div>)}
        <p className={`${succeeded ? "result-message" : "result-message hidden"}`}>Payment Succedded!</p>
      </div>
    </form>
  );
};

export default memo(CheckoutForm);
