import React, { memo } from 'react'
import utils from '../utils';

const DetailsItem = ({
    mode="single",
    item={},
    details={},
    formData={}
}) => (
    <div className="w-auto d-flex justify-content-start flex-wrap px-3 py-5 bg-light rounded-lg">
        <div 
            className="w-25 pr-3" 
            style={{ height: mode === 'single' ? '6rem' : '6rem'}}
        >
            <img 
                src={item.url || details?.url} 
                className="w-100 h-100 border border-3 border-primary rounded" 
                style={{ objectFit: "cover"}}
                alt="" 
            />
        </div>

        <section className="w-75 font-size-13">
            <div className="w-100 d-flex justify-content-start align-items-start pb-2">
                <div className="info__title">Item Name</div>
                <div className="text-truncate info__description">{item.name || details?.name}</div>
            </div>
            <div className="w-100 d-flex justify-content-start align-items-start pb-2">
                <div className="info__title">Quantity</div>
                <div className="info__description">{item.quantity || details?.quantity}</div>
            </div>

            {mode === "single" ?
                <>
                    <div className="w-100 d-flex justify-content-start align-items-start pb-2">
                        <div className="info__title">Sub total</div>
                        <div className="info__description">{item?.currency} {item?.sub_total}</div>
                    </div>
                    <div className="w-100 d-flex justify-content-start align-items-start pb-2">
                        <div className="info__title">
                            <span>Shipping Fee</span>
                            <p className="font-weight-bold font-size-10 mb-0">* Shipping by Cargo</p>
                        </div>
                        <div className="info__description">{item?.currency} {item?.shipping}</div>
                    </div>
                    {item?.discount > 0 && 
                        <div className="w-100 d-flex justify-content-start align-items-start pb-2">
                            <div className="info__title">Discount</div>
                            <div className="info__description">{item?.currency} {item?.discount}</div>
                        </div>
                    }
                    <div className="w-100 d-flex justify-content-start align-items-start pb-2 font-weight-bold">
                        <div className="info__title">Total</div>
                        <div className="info__description">{formData.currency} {utils.formatAmount(formData.amount)}</div>
                    </div>
                </>
            :
                <div className="w-100 d-flex justify-content-start align-items-start pb-2 font-weight-bold">
                    <div className="info__title">Price</div>
                    <div className="info__description">{item?.currency} {item?.price}</div>
                </div>                
            }
        </section>
    </div>
);

export default memo(DetailsItem);