import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import utils from "../utils";
import loading from "../assets/images/oval.svg";

const Paypal = ({
    amount="1.00",
    formData={},
    setOsiUrl=()=>{},
    GetClientSecret=()=>{},
    RegisterTransaction=()=>{},
    handlePaymentSuccess=()=>{},
    handlePaymentFailed=()=>{}
}) => {
    const [OrderID, setOrderID] = useState();

    return (
        <div>
            <div className="text-center mb-3 p-2 border border-primary rounded font-weight-bold text-primary">
                PAY {formData?.currency || 'USD'} {utils.formatAmount(amount)}
            </div>

            {formData?.currency ?
                <PayPalScriptProvider 
                    options={{
                        "client-id": process.env.REACT_APP_paypalClientID,
                        currency: formData?.currency,
                        intent: "capture"
                    }}
                >
                    <PayPalButtons 
                        style={{ layout: "horizontal" }}
                        forceReRender={[amount, formData]}
                        fundingSource="paypal"
                        disabled={false}
                        createOrder={ async (data, actions) => {
                            await GetClientSecret('paypal', { ...formData, payment_provider: 'paypal', provider: 'paypal' })

                            return actions.order
                                .create({
                                    purchase_units: [{
                                        amount: {
                                            value: amount
                                        },
                                        reference_id: formData.request_id
                                    }]
                                })
                                .then(async (orderId) => {
                                    setOrderID(orderId);
                                    RegisterTransaction(orderId, "CREATED", { ...formData, payment_provider: 'paypal', provider: 'paypal' });

                                    return orderId;
                                }, (reason) => {
                                    utils.log('Payment rejected: Reason=>', reason);
                                    handlePaymentFailed(reason);                                    
                                })
                        }}
                        onApprove={function (data, actions) {
                            return actions.order.capture().then(function (details) {
                                RegisterTransaction(details?.id , details?.status, { ...formData, payment_provider: 'paypal', provider: 'paypal' });

                                if (["COMPLETED", "APPROVED"].includes(details.status)) {
                                    setOsiUrl(`https://plendify.ositracker.com/sales/salejs/amount:${amount}/transaction:${formData?.request_id}`);
                                    handlePaymentSuccess(formData?.request_id, details?.status);
                                }

                                if (details.error === 'INSTRUMENT_DECLINED') {
                                    return actions.restart();
                                };                                    
                            });
                        }}
                        onError={(err) => {
                            RegisterTransaction(OrderID , "FAILED", { ...formData, payment_provider: 'paypal', provider: 'paypal' });
                            handlePaymentFailed(err);
                        }}
                        onCancel={(data) => {
                            RegisterTransaction(data?.orderID || OrderID, "CANCELLED", { ...formData, payment_provider: 'paypal', provider: 'paypal' });
                            handlePaymentFailed("Order Cancelled");
                        }}
                        onInit={(data) => { 
                            utils.log('Setting processing off..', data);
                        }}
                    />
                </PayPalScriptProvider>
            :
                <div className="w-100 d-flex justify-content-center">
                    <img src={loading} width="25" alt="" />
                </div>
            }
        </div>
    )
};

export default Paypal;
