import React from "react";

const Loader = ({
  loader=false
}) => {
  return (
    <div id="preloader-active" className="active position-absolute left-0 right-0">
      <div className={`preloader d-flex align-items-center justify-content-center ${loader ? 'block' : 'd-none hide'}`}>
        <div className="preloader-inner position-relative">
          <div className="preloader-circle"></div>
          <div className="preloader-img pere-text">
            <img
              src="favicon.png"
              alt="Plendify B2B Marketplace"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
