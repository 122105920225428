import React from "react";
import {
  BrowserRouter as Router,
  // Redirect,
  Route,
} from "react-router-dom";

// Pages
import PaymentPage from "./views/payment";

function App() { 
  return (
    <Router>
      <Route exact path="/" component={PaymentPage} className="transition-all" />
      <Route path="/payment_api_auto" component={PaymentPage} className="transition-all" />
      {/* <Redirect from="/" to="/payment_api_auto" className="transition-all" /> */}
    </Router>
  );
}

export default App;
