/* eslint-disable no-sequences */
import React, { useState, useEffect, useCallback, memo } from "react";
import { Form, Col, InputGroup, Button, Modal } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import utils from "../utils";

// Flutterwave integration
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";

// Stripe integration
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Paypal integrations
import Paypal from "../components/Paypal";
import OrderDetails from "../components/OrderDetails";

// Images
import plendifyPay from "../assets/images/plendify_pay.png";
import logo from "../assets/images/Plendify.png";
import loader from "../assets/images/spinner.svg";
import mtnImg from "../assets/images/mtn.png";
import vodaImg from "../assets/images/vodafone.png";
import airtigoImg from "../assets/images/airteltigo.png";
import paymentImg from "../assets/images/payment.svg";
import paypalImg from "../assets/images/paypal.png";
import paymentSuccess from "../assets/images/Successful purchase.gif";
import cardOptions from "../assets/images/card_payment.png";
import guranttee from "../assets/images/buyer_gurantte_white.svg";

// Components
import ModalUI from "../components/Modal";
import PageLoader from "../components/loader";
import CheckoutForm from "../components/CheckoutForm";

const promise = loadStripe(process.env.REACT_APP_stripeKey);
const baseUrl = process.env.REACT_APP_apiUrl;

const paymentElementApp = {
  appearance: {
    theme: 'stripe',
    variables: {
      fontWeightNormal: '500',
      borderRadius: '5px',
      colorBackground: 'white',
      colorPrimary: '#6a1b9a',
      colorPrimaryText: 'white',
      spacingGridRow: '15px'
    },
    rules: {
      '.Label': {
        marginBottom: '6px'
      },
      '.Tab, .Input, .Block': {
        boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)',
        padding: '12px'
      }
    }
  }   
};

const cardStyle = {
  style: {
    base: {
      color: "#6a1b9a",
      borderRadius: "6px",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#6a1b9a"
      }
    },
    invalid: {
      paddingTop: '5px',
      textAlign: 'center',
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  },
};

const Payment = () => {
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [showProviders, setshowProviders] = useState(true);
  const [loading, setloading] = useState(false);
  const [paymentStarted, setpaymentStarted] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setformData] = useState({
    amount: "1.00",
    currency: "",
    payment_provider: "",
    mobile_number: "",
    notes: "",
    request_id: "",
    token: "",
    payment_options: [],
    metadata: {},
  });
  const [providerFeedback, setproviderFeedback] = useState("");
  const [showGuide, setshowGuide] = useState("");
  const [showProduct, setshowProduct] = useState(false);
  const [showPaymentGuide, setshowPaymentGuide] = useState(false);
  const [checkingStatus, setcheckingStatus] = useState(false);
  const [paymentSucceeded, setpaymentSucceeded] = useState(false);
  const [isTimeout, setisTimeout] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [osiUrl, setOsiUrl] = useState(null);
  const [paymentConfig, setpaymentConfig] = useState({
    public_key: "",
    tx_ref: "",
    amount: "",
    currency: "",
    payment_options: " ",
    customer: {
      email: "",
      phonenumber: "",
      name: "",
    },
    customizations: {
      title: "Plendify",
      description: "Payment for items in cart",
      logo: logo,
    },
  });
  const [productList, setproductList] = useState([]);

  const getQueryParams = () =>
    window.location.search
      .replace("?", "")
      .split("&")
      .reduce(
        (r, e) => (
          (r[e.split("=")[0]] = decodeURIComponent(e.split("=")[1])), r
        ),
        {}
  );

  const handleFlutterPayment = useFlutterwave(paymentConfig);

  const handleClose = useCallback(() => setShow(false), []);

  const handleChange = useCallback((e, type) => {
    const { value } = e.target;
    setformData({
      ...formData,
      [type]: value,
    });
  }, []);

  const processTransaction = useCallback((provider, formInfo={}) => {
    try {
      let pageInfo = getQueryParams();
      const PaymentData = { ...formData, ...formInfo };
      PaymentData.request_id = pageInfo.request_id;
  
      let url = baseUrl + `/authorize-payment?request_id=${pageInfo.request_id}`;
      if (provider) url = `${url}&payment_provider=${provider}`;
  
      return new Promise((resolve, reject) => {
        return axios
        .post(url, PaymentData, {
          headers: {
            Authorization: "Bearer " + pageInfo.token,
            "Content-Type": "application/json",
            Client: "APP",
          },
        })
        .then((response) => {
          if (response.data?.client_secret) setClientSecret(response.data?.client_secret);
  
          resolve(response.data);
        })
        .catch((error) => {
          setpaymentStarted(false);
  
          Swal.fire({
            title: "Error",
            text: "Transaction could not be processed",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
          });
  
          reject(error);
        });
      });
    } catch (error) {
      Swal.fire({
        title: "Error Authorizing Payment!",
        text: error,
        icon: "error",
        allowOutsideClick: false,
        // allowEscapeKey: false,
        // confirmButtonText: "OK",
      })
    }
  }, []);  

  const handleProcessTrans = useCallback(() => {
    setpaymentStarted(true);
    processTransaction(formData.payment_provider)
      .then(() => {
        setShow(false);
        setpaymentStarted(false);
        setshowPaymentGuide(true);
        switch (formData.payment_provider.toString().toLocaleLowerCase()) {
          case "mtn":
            setshowGuide(
              "To complete payment: You'll receive a prompt to enter your PIN to confirm this transaction."
            );
            break;
          case "vodafone":
            setshowGuide(
              "Dial *700# and proceed to generate voucher for payment"
            );
            break;

          default:
            break;
        }
      })
      .catch((error) => {
        utils.log('Error making request');
      });
  }, []);  

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false || formData.payment_provider === "") {
      if (!formData.payment_provider) {
        setproviderFeedback("Please select a payment_provider");
      } else setproviderFeedback(null);

      event.preventDefault();
      event.stopPropagation();
    } else {
      setproviderFeedback(null);
      handleProcessTrans();
    }

    setValidated(true);
  }, []);

  const setImg = useCallback((payment_provider) => {
    switch (payment_provider.toString().toLowerCase()) {
      case "mtn":
        return mtnImg;
      case "vodafone":
        return vodaImg;
      case "airteltigo":
        return airtigoImg;

      default:
        break;
    }
  }, []);

  const getPaymentInfo = useCallback(() => {
    let pageInfo = getQueryParams();

    setformData({ ...formData, token: pageInfo.token });
    let url = `${baseUrl}/request?id=${pageInfo.request_id}`;

    if (!pageInfo.request_id) return;
    
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + pageInfo.token,
          Client: "APP",
          "Content-Type": "application/json",
        }
      })
      .then((response) => {
        setformData({ ...formData, ...response.data });

        if (pageInfo?.payment_provider === "stripe") {
          // Payment intent needed in order to load new Strip Element UI
          processTransaction("stripe", response.data); 
        }

        // Set flutterwave config variables
        if (pageInfo.payment_provider === "flutterwave") {
          setpaymentConfig({
            ...paymentConfig,
            public_key: process.env.REACT_APP_flutterwave,
            tx_ref: pageInfo.request_id,
            amount: response.data.amount,
            currency: response.data.currency,
            customer: {
              ...paymentConfig.customer,
              email: response.data?.metadata?.email,
            },
          });
        }

        if (response.data?.payment_options?.length <= 0) {
          setshowProviders(true);
        }

        // Set order details if it exists
        if (response.data?.metadata?.order_details) {
          const details = response.data?.metadata?.order_details;

          const products = details?.products?.map((el) => {
            return {
              ...el,
              shipping: details?.delivery_cost,
              price: details?.item_cost,
              quantity: details?.products.length,
              sub_total: details?.item_cost * details?.products.length,
              total: (details?.item_cost * details?.products.length) + details?.delivery_cost,
              currency: details?.currency?.symbol,
              url: details?.products[0]?.images[0]?.url
            }
          });

          setproductList(products);
        }
        
        setloading(false);
      })
      .catch((error) => {
        utils.log('Error summary:', error);

        Swal.fire({
          title: "Sorry!",
          text: "We are unable to process your request. Please try again later",
          icon: "error",
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonText: "OK",
        }).then((onOk) => {
          // history.goBack();
          utils.log('onOk:', onOk);
        });
        setloading(false);
      });
  }, []);

  const registerTransaction = useCallback((provider_reference, status, formInfo={}) => {
    let pageInfo = getQueryParams();
    const PaymentData = { ...formData, ...formInfo }
    PaymentData.request_id = pageInfo.request_id;

    let url = baseUrl + `/paypal-status?request_id=${pageInfo.request_id}`;
    if (provider_reference) url = `${url}&provider_reference=${provider_reference}`; 
    if (status) PaymentData.status = status   

    return new Promise((resolve, reject) => {
      return axios
        .post(url, PaymentData, {
          headers: {
            Authorization: "Bearer " + pageInfo.token,
            "Content-Type": "application/json",
            Client: "APP",
          },
        })
        .then((response) => {
          if (response.data?.client_secret)
          resolve(response.data);
        })
        .catch((error) => {
          setpaymentStarted(false);
          reject(error);
        });
    });    
  }, []);

  const statusChecker = useCallback(() => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          baseUrl +
            `/transaction-status?request_id=${getQueryParams().request_id}`,
          {
            headers: {
              Authorization: "Bearer " + getQueryParams().token,
              "Content-Type": "application/json",
              Client: "APP",
            },
          }
        )
        .then((response) => {
          let status = response.data.status?.toLowerCase();

          if (status === "pending" || status === "queued") {
            if (!isTimeout) {
              setTimeout(() => {
                statusChecker();
              }, 5000);
            }
          }
          if (status === "failed") {
            setcheckingStatus(false);
            Swal.fire({
              title: "Failed!",
              text: "Transaction Failed",
              icon: "error",
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonText: "Okay",
              footer: `Need assistance? <a href=${process.env.REACT_APP_support} target="_blank" rel="noreferrer"><span class="black-text pl-1">Chat with us</span></a>`,
            }).then((onOk) => {
              if (onOk) {
                if (formData.cancel_url) {
                  window.location.replace(formData.cancel_url);
                } else history.goBack();
              }
            });
          }

          if (status === "success") {
            setpaymentSucceeded(true);
            Swal.fire({
              title: "Payment Successful",
              text: "You completed your payment successfully. A receipt will be sent to your email shortly.",
              icon: "success",
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonText: "Back to store",
              footer: `Need assistance? <a href=${process.env.REACT_APP_support} target="_blank" rel="noreferrer"><span class="black-text pl-1">Chat with us</span></a>`,
            }).then((onOk) => {
              if (onOk) {
                if (formData.return_url) {
                  window.location.replace(formData.return_url);
                } else history.goBack();
              }
            });
          }

          resolve(response);
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "Error fetching transaction status",
            icon: "error",
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: "OK",
          }).then((onOk) => {
            setcheckingStatus(false);
          });
          reject(error);
        });
    });
  }, []);  

  const startStatusCheck = useCallback(() => {
    setshowPaymentGuide(false);
    setcheckingStatus(true);

    var pol = "";
    pol = statusChecker();

    setTimeout(() => {
      setisTimeout(true);
      pol = undefined;

      Swal.fire({
        title: "Request Timeout!",
        text: "Transaction Failed, do you want to retry?",
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((onOk) => {
        if (onOk) {
          setcheckingStatus(false);
        } else {
          if (formData.cancel_url) {
            history.push(formData.cancel_url);
          } else history.goBack();
        }
      });
    }, 60000 * 5);
  }, []);

  const confirmCancel = useCallback(() => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
      footer: `Need assistance? <a href=${process.env.REACT_APP_support} target="_blank" rel="noreferrer"><span class="black-text pl-1">Chat with us</span></a>`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Cancelled!",
          text: "Transaction cancelled, redirecting...",
          icon: "info",
          showConfirmButton: false,
        });

        setTimeout(() => {
          history.goBack();
        }, 2000);
      }
    });
  }, []);

  const handlePaymentSuccess = useCallback((request_id, status) => {
    setpaymentSucceeded(true);
    Swal.fire({
      title: "Payment Successful",
      text: `${utils.present(formData?.metadata?.order_details) ? 
        'You have completed your payment. You can track your order under My Orders.' 
      : 
        'Your payment was successful'
      }`,
      icon: "success",
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: `${utils.present(formData?.metadata?.order_details) ? 
        'My Orders'
      :
        'Continue'
      }`,
      footer: `Need assistance? <a href=${process.env.REACT_APP_support} target="_blank" rel="noreferrer"><span class="black-text pl-1">Chat with us</span></a>`
    })
      .then(onOk => {
        const pageInfo = getQueryParams();
        if (onOk) {
          let url = baseUrl + `/redirect?request_id=${pageInfo.request_id}`
          return new Promise((resolve, reject) => {
            return axios.get(url, {
              headers: {
                Authorization: "Bearer " + pageInfo.token,
                "Content-Type": "application/json",
                Client: "APP",
              },
            })
            .then((response) => {
              window.location.replace(response.data)
              resolve(response);
            })
            .catch((error) => {
              setpaymentStarted(false);
              window.location.replace(formData.return_url || formData.callback_url);

              // Swal.fire({
              //   title: "Error",
              //   text: "redirection",
              //   icon: "error",
              //   showConfirmButton: false,
              //   timer: 3000,
              // });

              reject(error);
            });
        });
      }
    });
  }, []);

  const handlePaymentFailed = useCallback((message) => {
    Swal.fire({
      title: "Payment failed",
      html: `<span id="card-error" className="font-size-14">${message}</span>`,
      icon: "error",
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "OK",
    }).then(() => {});
  }, []);

  const handlePaymentCancelled = useCallback(() => {
    Swal.fire({
      title: "Payment cancelled",
      text: "",
      icon: "info",
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "OK",
    }).then(() => {});
  }, []);

  const handleWavePaymentComplete = useCallback((info) => {
    switch (info?.status) {
      case "successful":
        setOsiUrl(
          `https://plendify.ositracker.com/sales/salejs/amount:${
            info?.amount / 100
          }/transaction:${info?.request_id}`
        );
        handlePaymentSuccess(formData.request_id, "SUCCESS");

        break;

      case "failed":
        handlePaymentFailed(
          "There is a temporary issue connecting to our payment provider. Try again in a few minutes. For support please send us a message in the chat."
        );

        break;

      default:
        break;
    }
  }, []);  

  const handleFlutterwave = () => {
    setpaymentStarted(true);

    processTransaction(getQueryParams()?.payment_provider, formData)
    .then((res) => {
      utils.log('rave info', res);
      setpaymentStarted(true);
      
      try {
        handleFlutterPayment({
          callback: (response) => {
            setpaymentStarted(false);
            closePaymentModal(); // this will close the modal programmatically
            handleWavePaymentComplete(response);
          },
          onClose: () => {
            setpaymentStarted(false);
          },
        });        
      } catch (error) {
        setpaymentStarted(false);
        utils.log('Error loading flutterwave');
      }
    })
    .catch(() => {
      setpaymentStarted(false);
      utils.log("Error registering transaction");
    });    
  };

  const handleShowAll = useCallback(() => {
    setshowProduct(true)
  }, []);

  // Local Component
  const StripeFallback = ({ elementType, clientSecret, others={} }) => (
    <Elements 
      stripe={promise} 
      options={{
        clientSecret: clientSecret,
        ...others
      }}
    >
      <CheckoutForm
        elementType={elementType}
        stripe={promise}
        amount={formData.amount}
        currency={formData.currency}
        clientSecret={clientSecret}
        formData={formData}
        GetClientSecret={processTransaction}
        setOsiUrl={setOsiUrl}
        handlePaymentSuccess={handlePaymentSuccess}
        handlePaymentFailed={handlePaymentFailed}
      />
    </Elements>
  );

  useEffect(() => {
    ( async function () {
      getPaymentInfo();
  
      const pageInfo = getQueryParams();
  
      if (pageInfo?.payment_provider) {
        setformData({
          ...formData,
          payment_provider: pageInfo?.payment_provider,
        });
        setshowProviders(false);
      }
      
    }())

    return () => {};
  }, []);

  return (
    <div className="payment-body position-relative">
      <PageLoader loader={loading} />

      <div className="row h-screen overflow-hidden">
        <div className="col-md-6 px-5 d-none d-md-block bg-left position-relative h-100 overflow-hidden">
          <div className="d-flex align-items-center justify-content-center h-100">
            
            <div className="w-100 mx-auto position-absolute left right top">
              <div
                className="bg-primary py-4 text-white w-100 pl-5"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  confirmCancel();
                }}
              >
                <i className="fas fa-caret-left"></i>
                <span className="h5 pl-3">Cancel Payment</span>
              </div>
            </div>

            {formData?.metadata?.order_details ?
              <div className="w-100">
                <OrderDetails 
                  title="Order Details"
                  info={formData?.metadata?.order_details}
                  formData={formData}
                  showAll={handleShowAll}
                />
              </div>
            :
              <div className="d-flex w-100 justify-content-center">
                {paymentSucceeded ? (
                  <img src={paymentSuccess} style={{ width: "60%" }} alt="" />
                ) : (
                  <img src={paymentImg} style={{ width: "60%" }} alt="" />
                )}
              </div>
            }

            <div className="d-flex w-100 justify-content-between align-items-end mx-auto px-5 pb-2 position-absolute left right bottom">
              {formData?.metadata?.order_details ? 
                <>
                  {paymentSucceeded ? (
                    <img src={paymentSuccess} style={{ width:"20%" }} alt="" />
                  ) : (
                    <img src={paymentImg} style={{ width:"20%" }} alt="" />
                  )}
                </>
              :
                <div />
              }

              <div className="d-flex flex-column align-items-center">
                <img src={guranttee} className="" style={{ width: "60%" }} alt="" />
                <p className="font-20 mb-0 text-white font-weight-bold text-right">Powered by Plendify</p>
              </div>
            </div>
          </div>
        </div>

        <div className={`col-md-6 bg-white h-screen overflow-auto ${!clientSecret  ? 'd-block d-sm-flex align-items-center justify-content-center' : ''}`}>
          <div className="row overflow-auto">
            <div className="payment-side overflow-auto mx-auto">
              <div className="d-flex align-items-center justify-content-center py-4">
                <div className="px-2">
                  <img src={plendifyPay} className="plendify_pay" alt="" />
                </div>
              </div>

              <div className="card p-3">
                <div className="pb-3 d-flex justify-content-evenly pay_option">
                  <ul
                    role="tablist"
                    className="nav nav-pills rounded nav-fill mb-2 flex justify-content-between w-100"
                  >
                    <li className={`nav-item ${getQueryParams()?.payment_provider === "stripe" ? "w-50" : "w-100"}`}>
                      <a data-toggle="tab" href="#credit-card" className="nav-link active">
                        <i className="fas fa-credit-card"></i>
                        <span className="pl-1">Credit/Debit Cards</span>
                      </a>
                    </li>
                    <li className={`nav-item ${getQueryParams()?.payment_provider === "stripe" ? "w-50" : "hidden"}`}>
                      <a data-toggle="tab" href="#paypalBtn" className="nav-link">
                        <img src={paypalImg} width={16} alt="" />
                        <span className="pl-1">Paypal</span>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="tab-content">
                  <div
                    id="credit-card"
                    className="tab-pane fade p-3 border border-primary rounded--cus show active"
                  >
                    {getQueryParams()?.payment_provider === "stripe" && (
                      <>
                        {clientSecret ?
                          <StripeFallback 
                            elementType="payment" 
                            clientSecret={clientSecret} 
                            others={paymentElementApp} 
                          />
                        :
                          <> 
                            <StripeFallback 
                              elementType="card" 
                              others={cardStyle}
                            />
                            
                            <p className="text-black my-2 notes font-size-14 text-center">
                              Your payment details are securely encrypted and powered by Stripe.
                            </p>
                          </>
                        }
                      </>
                    )}

                    {getQueryParams()?.payment_provider === "flutterwave" && (
                      (<div>
                        <p className="text-black my-2 notes font-size-14 text-center">
                          Your payment details are securely encrypted and powered by Flutterwave.
                        </p>

                        <button
                          onClick={handleFlutterwave}
                          className="position-relative d-flex justify-content-center align-items-center rounded"
                          style={{ height: '2.2rem' }}
                        >
                          {paymentStarted ? (
                            <div className="spinner" id="spinner"></div>
                          ) : (
                            <>PAY {formData?.currency}{" "}{utils.formatAmount(formData.amount)}</>
                          )}                          
                        </button>
                      </div>)
                    )}

                    <div>
                      <div className="font-size-14 text-center text-black pt-2">
                        Need assistance?
                        <a
                          href={process.env.REACT_APP_support}
                          target="_blank"
                          rel="noreferrer"
                          className="pl-1"
                        >
                          <span className="black-text">Chat with us</span>
                        </a>{" "}
                        or call
                        <a href="tel:18889042349" className="pl-1">
                          <span className="black-text">+1-888-904-2349</span>
                        </a> /  
                        <a href="tel:233308250095">
                          <span className="black-text">+233-(30)-825-0095</span>
                        </a>
                      </div>

                      <div className="d-flex align-items-center justify-content-center pt-3">
                        <img
                          src={cardOptions}
                          style={{ width: "15rem" }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  {getQueryParams()?.payment_provider === "stripe" && 
                    <div id="paypalBtn" className="tab-pane fade p-3 border border-primary rounded--cus show">
                      <Paypal 
                        amount={formData.amount}
                        currency={formData.currency}
                        clientSecret={clientSecret}
                        formData={formData}
                        GetClientSecret={processTransaction}
                        RegisterTransaction={registerTransaction}
                        setOsiUrl={setOsiUrl}
                        handlePaymentSuccess={handlePaymentSuccess}
                        handlePaymentFailed={handlePaymentFailed}
                        handlePaymentCancelled={handlePaymentCancelled}
                      />

                      <div>
                        <p className="text-black my-2 notes font-size-14 text-center">
                          Your payment details are securely encrypted and powered
                          by Paypal.
                        </p>
                        <div className="font-size-14 text-center text-black pt-2">
                          Need assistance?
                          <a
                            href={process.env.REACT_APP_support}
                            target="_blank"
                            rel="noreferrer"
                            className="pl-1"
                          >
                            <span className="black-text">Chat with us</span>
                          </a>{" "}
                          or call
                          <a href="tel:18889042349" className="pl-1">
                            <span className="black-text">+1-888-904-2349</span>
                          </a> /  
                          <a href="tel:233308250095">
                            <span className="black-text">+233-(30)-825-0095</span>
                          </a>
                        </div>
                        <div className="d-flex align-items-center justify-content-center pt-3">
                          <img
                            src={cardOptions}
                            style={{ width: "15rem" }}
                            alt=""
                          />
                        </div>
                      </div>                    
                    </div>
                  }

                  <div
                    id="momo"
                    className="tab-pane fade p-3 border border-primary rounded--cus momo invisible"
                  >
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                      {showProviders && (
                        <Form.Row>
                          <Form.Label className="mb-3">
                            Select your provider
                          </Form.Label>
                          <Form.Group
                            className="w-100 mb-0"
                            controlId="validationNetworkSelection"
                          >
                            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mb-1">
                              <label className="radio-inline">
                                <input
                                  value="MTN"
                                  type="radio"
                                  className="form-check-input"
                                  name="payment_provider"
                                  onChange={(e) =>
                                    handleChange(e, "payment_provider")
                                  }
                                />
                                <div className="checkmark"></div>
                                <img src={mtnImg} width="70" alt="" />
                              </label>
                              <label className="radio-inline">
                                <input
                                  value="vodafone"
                                  type="radio"
                                  className="form-check-input"
                                  name="payment_provider"
                                  onChange={(e) =>
                                    handleChange(e, "payment_provider")
                                  }
                                />
                                <div className="checkmark"></div>
                                <img src={vodaImg} width="70" alt="" />
                              </label>
                              <label className="radio-inline">
                                <input
                                  value="airteltigo"
                                  type="radio"
                                  className="form-check-input"
                                  name="payment_provider"
                                  onChange={(e) =>
                                    handleChange(e, "payment_provider")
                                  }
                                />
                                <div className="checkmark"></div>
                                <img src={airtigoImg} width="70" alt="" />
                              </label>
                            </div>
                            <div className={`${providerFeedback ? "d-block" : ""} invalid-feedback`}>
                              {providerFeedback}
                            </div>
                          </Form.Group>
                        </Form.Row>
                      )}

                      <Form.Group
                        as={Col}
                        className="px-0"
                        controlId="validationCustomPhone"
                      >
                        <div className="text-black pb-4">
                          Enter the number associated with your{" "}
                          {formData.payment_provider} Mobile Money Wallet
                        </div>
                        <Form.Label className="text-black-50">
                          Phone Number
                        </Form.Label>
                        <InputGroup>
                          <div className="input-group-prepend rounded-left">
                            <span className="input-group-text">+233</span>
                          </div>
                          <Form.Control
                            name="mobile_number"
                            type="text"
                            placeholder="(0) XX XXX XXXX"
                            onChange={(e) => handleChange(e, "mobile_number")}
                            className="w-full rounded-right rounded-right"
                            aria-describedby="inputGroupPrepend"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a phone number.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>

                      <div className="py-2">
                        <Button
                          type="submit"
                          className="subscribe btn btn-primary btn-block shadow-sm"
                          // disabled={validated}
                        >
                          {paymentStarted ? (
                            // <>
                            //   <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            //   <span className="sr-only">Loading...</span>
                            // </>
                            <div className="spinner" id="spinner"></div>
                          ) : (
                            `PAY ${formData?.currency} ${formData.amount}`
                          )}
                        </Button>
                      </div>
                    </Form>

                    <p className="text-black my-2 notes text-center">
                      Your payment details are securely encrypted and powered by
                      Stripe.
                    </p>

                    <div className="font-size-14 text-center text-black pt-2">
                      Need assistance?
                      <a
                        href={process.env.REACT_APP_support}
                        target="_blank"
                        rel="noreferrer"
                        className="pl-1"
                      >
                        <span className="black-text">Chat with us</span>
                      </a>{" "}
                      or call
                      <a href="tel:18889042349" className="pl-1">
                        <span className="black-text">+1 888-904-2349</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Show all order details items */}
      <ModalUI show={showProduct} setShow={setshowProduct}>
        <OrderDetails 
          info={formData.metadata?.order_details} 
          mode="all" 
        />
      </ModalUI>

      {/* Confirmation Modal */}
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header>
          <Modal.Title>
            <span className="text-primary font-weight-bold">PLENDIFY</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bg-light p-4">
            {/* <div className="d-flex justify-content-between pb-2">
                <div>Platform Fee</div>
                <div>GHS 0.00</div>
              </div> */}
            <div className="d-flex justify-content-between pb-2">
              <div>Amount</div>
              <div>GHS {formData.amount}</div>
            </div>
          </div>
          <div className="px-4">
            <h2 className="h5 pt-2">Payment Method</h2>
            <div className="pt-2">
              <div className="d-flex justify-content-between p-2 border rounded">
                <div className="d-flex align-items-center">
                  <img
                    src={setImg(formData.payment_provider)}
                    width="30"
                    alt=""
                  />
                  <span className="pl-3 text-capitalize">
                    {formData.mobile_number}
                  </span>
                </div>
                <div>
                  <Button variant="link" onClick={handleClose}>
                    Change
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center py-4">
            <h1 className="text-primary font-weight-bold">
              GHS {formData.amount}
            </h1>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="w-100"
            variant="primary"
            onClick={() => handleProcessTrans()}
          >
            {paymentStarted ? (
              <>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              `PAY`
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Telco payment process notification */}
      <Modal
        show={showPaymentGuide}
        onHide={() => {
          startStatusCheck();
        }}
        backdrop="static"
        centered
      >
        <Modal.Body>
          <section className="bg-light p-3">
            <div className="text-center font-weight-bold font-size-18">
              Approval Required
            </div>
            <div className="secondary-text text-uppercase pt-3 font-weight-bold font-size-14">
              DO NOT REFRESH OR LEAVE THIS PAGE
            </div>
            <div className="py-4">
              <p className="font-size-14">
                To complete payment, you will receive a prompt on your device to
                enter your PIN to confirm transaction.
              </p>
              <p className="font-size-14">
                If you do not receive the prompt after some few minutes, dial
                *170# and select the option number 6 for ‘My Wallet’ and check
                approvals to complete transaction
              </p>
            </div>
            <Button
              className="w-100"
              variant="primary"
              onClick={() => {
                startStatusCheck();
              }}
            >
              Okay
            </Button>
          </section>
        </Modal.Body>
      </Modal>

      {/* Processing payment screen */}
      {checkingStatus && (
        <div className="position-absolute left right top bottom bg-primary d-flex flex-column justify-content-center align-items-center processing animate">
          <i
            className="fas fa-times text-white position-absolute right top p-2"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              // setSmShow(true)
              confirmCancel();
            }}
          ></i>
          <div className="text-white">Processing Transaction</div>
          <div className="text-white pt-1 d-flex flex-column align-items-center justify-content-center">
            {/* <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> */}
            <img src={loader} className="w-75" alt="loader" />
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      {osiUrl && (
        <iframe
          title={osiUrl}
          src={osiUrl}
          className="hidden"
          allowFullScreen={true}
          hidden
        />
      )}
    </div>
  );
};

export default memo(Payment);
